const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                console.log("Service Worker registered with scope:", registration.scope);
                // Optional: Implement update logic here

                // this code block ensures that when a new version of the service worker is found and installed, the user is notified to refresh the page to load the new version.
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker) {
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === "installed" && navigator.serviceWorker.controller) {
                                // Trigger UI update to notify the user of a new version
                                console.log("New content is available; please refresh.");
                            }
                        };
                    }
                };
            })
            .catch((error) => {
                console.error("Error registering Service Worker:", error);
                // Optional: Handle registration failure
            });
    }
};

export default registerServiceWorker;
