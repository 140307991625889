import banner from "../../../assets/images/countries/slovaka3-mid.webp";
import flag from "../../../assets/images/flags/slovak.webp";

const sloveniaData = {
  banner,
  flag,
  name: "Slovenia",
  initials: "SL",
  navigateOnSelectLink: "/slovenia/checkout",
  mission: "otherPage.mission",
  travelTo: "Slovenia page heading",
  chooseYourVehicle: "otherPage.chooseYourVehicleForSlovakia",
  eVigInCountry: "otherPage.eVigInSlovak",
  eVigInCountryDesc: "otherPage.buyingAnEVigInSlovak",
  typesOfVigDescCountry: "otherPage.typesOfVDescSlovak",
  aboutCountry: "otherPage.aboutSlovak",
  aboutCountryDesc: "otherPage.aboutDescSlovak",
};

export default sloveniaData;
