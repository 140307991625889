import React from 'react';
import '../styles/404Page.scss';


const NotFoundPage = () => {
    return (
        <div className="notFoundContainer">
            <div className='notFound'>
                <div>
                    <h1>404 - Not Found</h1>
                    <p>Sorry, the page you are looking for might be in another castle.</p>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
