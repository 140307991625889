const initialState = {
  price: {},
  ecoFee: {},
  currency: "null",
  currencies: [],
};

export const vignetteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRICE":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_CURRENCY":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_CURRENCIES":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_PRICE":
      return {
        ...state,
        price: {},
        ecoFee: {},
      };
    default:
      return state;
  }
};
