import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function AcceptCookie() {
  const [isShown, setIsShown] = useState(false);

  const { t } = useTranslation();


  useEffect(() => {
    if (localStorage.getItem("userAllowsCookies") != null) return;
    const timeout = setTimeout(() => {
      setIsShown(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleAccept = () => {
    localStorage.setItem("userAllowsCookies", true);
    setIsShown(false);
  };

  const handleReject = () => {
    localStorage.setItem("userAllowsCookies", false);
    setIsShown(false);
  };
  if (!isShown) return <></>;
  return (
    <div className="accept-cookie">
      <div>
        <div className="accept-description">
          <span >
            {t("cookiePage.cookiePolicy")}
          </span>
          <Link to={`/${localStorage.getItem("lng")}/cookie-policy`}>
            <strong>{t("cookiePage.heading").toLowerCase()}</strong>
          </Link>
        </div>
        <div className="buttonGroup">
          <Button variant="contained" style={{ boxShadow: "none", margin: "0px" }} onClick={handleAccept}>
            {t("cookiePage.agreeAndClose")}
          </Button>
          <Button variant="contained" style={{ boxShadow: "none", margin: "0px" }} onClick={handleReject}>
            {t("cookiePage.decline")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AcceptCookie;
