import { combineReducers } from "redux";
import { vignetteReducer } from "./vignette";
import { vehicleReducer } from "./vehicle";
import { checkoutInfoReducer } from "./checkoutInfo";
const allReducers = combineReducers({
  vignette: vignetteReducer,
  vehicle: vehicleReducer,
  checkoutInfo: checkoutInfoReducer,
});

export default allReducers;
