import React, { useEffect, useRef, Suspense } from "react";
import "../styles/home/home.scss";
import "../styles/home/SearchBar.scss"


import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button, Container } from "@mui/material";
import useNavigateCustom from "../hooks/useNavigateCustom";
import FallbackCompLoader from "../components/FallbackCompLoader";
import BannerImage from "../assets/images/newBannerImageUpscaled.webp"
import HomeTopComp from "../components/home/HomeTopComp";
import HeaderWithStyledLastWord from "../components/home/HeaderWithStyle";


const SearchFilterComp = React.lazy(() => import("../components/home/SearchFilterComp"));
const Places = React.lazy(() => import("../components/home/Places"));
const EuropeMap = React.lazy(() => import("../components/EuropeMap"));
const Reviews = React.lazy(() => import("../components/home/Reviews"));




const Home = () => {
  const { lng } = useParams();
  const { hash } = useLocation();
  const navigate = useNavigateCustom();
  const { t } = useTranslation();
  const contriesGridContainerRef = useRef(null);

  const scrollToContriesGrid = () => {
    if (contriesGridContainerRef.current) {
      contriesGridContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  const preloadLCPImage = () => {
    const link = document.createElement('link')
    link.rel = 'preload'
    link.as = 'image'
    link.href = BannerImage;
    document.head.appendChild(link)
  }

  useEffect(() => {
    if (lng === undefined) navigate("/");
    if (hash === "#buy") {
      scrollToContriesGrid();
    }

    preloadLCPImage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="homepage">

      <HomeTopComp
        scrollToContriesGrid={scrollToContriesGrid}
      />

      <Suspense fallback={<FallbackCompLoader />}>
        <SearchFilterComp />
      </Suspense>


      <Suspense fallback={<FallbackCompLoader />}>
        <Places contriesGridContainerRef={contriesGridContainerRef} />
      </Suspense>


      <Suspense fallback={<FallbackCompLoader />}>
        <EuropeMap />
      </Suspense>

      <Container maxWidth="lg" className="define-container">
        <HeaderWithStyledLastWord text={t("homepage.whatIsV")} numLastWords={2} />
        <p style={{ textAlign: "justify" }}>{t("homepage.whatIsVDesc")}</p>
      </Container>

      <Reviews />
      <div className="btm-btn-container">
        <Button
          variant="contained"
          className="btm-btn"
          onClick={scrollToContriesGrid}
        >
          <span className="shoppingCartIcon">
            <ShoppingCartIcon /> <span>&nbsp; </span>
          </span>
          <span>BUY VIGNETTES</span>
        </Button>
      </div>
    </div>
  );
};

export default Home;
