import { useNavigate, useParams } from "react-router-dom";

const useNavigateCustom = () => {
  const navigate = useNavigate();
  const { lng } = useParams();
  const useNavigateCustom = (link) => {
    if (!lng) {
      navigate(`/${localStorage.getItem("lng")}`);
    } else {
      navigate(`/${lng}${link}`);
    }
  };

  return useNavigateCustom;
};

export default useNavigateCustom;
