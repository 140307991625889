import banner from "../../../assets/images/countries/slovaka3-mid.webp";
import flag from "../../../assets/images/flags/moldovan.webp";

const moldovaData = {
  banner,
  flag,
  name: "Moldova",
  initials: "MD",
  navigateOnSelectLink: "/moldova/checkout",
  mission: "otherPage.mission",
  travelTo: "Moldova page heading",
  chooseYourVehicle: "otherPage.chooseYourVehicleForSlovakia",
  eVigInCountry: "otherPage.eVigInSlovak",
  eVigInCountryDesc: "otherPage.buyingAnEVigInSlovak",
  typesOfVigDescCountry: "otherPage.typesOfVDescSlovak",
  aboutCountry: "otherPage.aboutSlovak",
  aboutCountryDesc: "otherPage.aboutDescSlovak",
};

export default moldovaData;
