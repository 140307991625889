import axios from "./axiosConfig";

const loadCountries = async () => {
  try {
    let data = await axios.get("/countries");
    data = data.data;
    data = data.sort((a, b) => {
      const nameA = a.name["en"].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const nameB = b.name["en"].toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export default loadCountries;
