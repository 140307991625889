import banner from "../../../assets/images/countries/austria2-mid.webp";
import bannerSmall from "../../../assets/images/countries/austria2-small.webp";
import flag from "../../../assets/images/flags/austria.webp";

const austriaData = {
  banner,
  bannerSmall,
  flag,
  name: "Austria",
  initials: "AU",
  navigateOnSelectLink: "/austria/checkout",
  mission: "otherPage.mission",
  travelTo: "otherPage.travelToAustria",
  chooseYourVehicle: "otherPage.chooseYourVehicleForAustria",
  eVigInCountry: "otherPage.eVigInAustria",
  eVigInCountryDesc: "otherPage.eVigInAustriaDesc",
  typesOfVigDescCountry: "otherPage.typesOfVigThatNeedsDesc",
  aboutCountry: "otherPage.aboutAustria",
  aboutCountryDesc: "otherPage.aboutDescAustria",
};

export default austriaData;
