import banner from "../../../assets/images/countries/czech-mid.webp";
import bannerSmall from "../../../assets/images/countries/czech-small.webp";
import flag from "../../../assets/images/flags/czech.webp";

const czechRepublicData = {
  banner: banner,
  bannerSmall,
  flag,
  name: "Czech Republic",
  initials: "CZ",
  navigateOnSelectLink: "/czech-republic/checkout",
  mission: "otherPage.mission",
  travelTo: "otherPage.traveToCzech",
  chooseYourVehicle: "otherPage.chooseYourVehicle",
  eVigInCountry: "otherPage.eVigInCzech",
  eVigInCountryDesc: "otherPage.buyingAnEVig",
  typesOfVigDescCountry: "otherPage.typesOfVDesc",
  aboutCountry: "otherPage.about",
  aboutCountryDesc: "otherPage.aboutDesc",
};

export default czechRepublicData;
