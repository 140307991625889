import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import DynamicTitle from "./components/DynamicTitle";
import NotFoundPage from "./components/404Page";
import useLanguage from "./hooks/useLanguage";
import AcceptCookie from "./components/AcceptCookie";
import Home from "./pages/Home";
import Appbar from "./components/Appbar";
import Footer from "./components/Footer";






// Lazy loading components to improve initial loading time , prevents immediate execution of js bundle for these components
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Faq = lazy(() => import("./pages/Faq"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const CountryPage = lazy(() => import("./pages/countries/CountryPage"));
const CheckoutPage = lazy(() => import("./pages/checkoutPages/CheckoutPage"));
const OrderInfo = lazy(() => import("./pages/checkoutPages/OrderInfo"));

const App = () => {
  useLanguage();
  return (
    <BrowserRouter>
      <Appbar />

      <ScrollToTop />
      <DynamicTitle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/order/success" element={<OrderInfo />} />
        <Route path="/:lng/*" element={<MainRoutes />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <AcceptCookie />
      <Footer />

    </BrowserRouter>
  );
};

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="about-us" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="faq" element={<Faq />} />
      <Route path="cookie-policy" element={<CookiePolicy />} />
      <Route path="country/:countryName" element={<CountryPage />} />
      <Route path="country/:countryName/checkout" element={<CheckoutPage />} />
      <Route path="order/success" element={<OrderInfo />} />
      <Route path="page-not-found" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
