import banner from "../../../assets/images/countries/slovaka3-mid.webp";
import flag from "../../../assets/images/flags/bulgaria.webp";

const bulgariaData = {
  banner,
  flag,
  name: "Bulgaria",
  initials: "BL",
  navigateOnSelectLink: "/bulgaria/checkout",
  mission: "otherPage.mission",
  travelTo: "Bulgaria page heading",
  chooseYourVehicle: "otherPage.chooseYourVehicleForSlovakia",
  eVigInCountry: "otherPage.eVigInSlovak",
  eVigInCountryDesc: "otherPage.buyingAnEVigInSlovak",
  typesOfVigDescCountry: "otherPage.typesOfVDescSlovak",
  aboutCountry: "otherPage.aboutSlovak",
  aboutCountryDesc: "otherPage.aboutDescSlovak",
};

export default bulgariaData;
