import { Suspense } from "react"
import FallbackCompLoader from "../FallbackCompLoader"
import SearchBar from "../SearchBar"
import HeaderCard from "./HeaderCard"
import BannerImage from "../../assets/images/newBannerImageUpscaled.webp"



const HomeTopComp = ({
    scrollToContriesGrid
}) => {
    return (
        <div className="home">


            <Suspense fallback={<FallbackCompLoader />} >
                <div className="card-search-container">
                    <HeaderCard scrollToContriesGrid={scrollToContriesGrid} />
                    <SearchBar />
                </div>

            </Suspense>

            <img
                fetchPriority="high"
                alt="banner background"
                src={BannerImage}
                className="home-bannerImage"
            />

        </div>)
}
export default HomeTopComp