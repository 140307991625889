import AnimatedLottie from "./LoadingAnimation"
import loadingAnimationData from "../assets/loadingAnimaltion.json";
import "../styles/CarLoader.scss"


const CarLoader = () => {
    return (
        <div className="animatedCarLoader">
            <AnimatedLottie animationData={loadingAnimationData} width={300} />
        </div>
    )
}
export default CarLoader