// When useNavigate is used to open new pages, it opens new page at the
// same scroll position, this is used to reset the scroll position to the top
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top when the route changes
	}, [pathname]);

	return null; // This component doesn't render anything
}

export default ScrollToTop;
