import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import axios from "../api/axiosConfig";
import { useDispatch } from "react-redux";
import useNavigateCustom from "../hooks/useNavigateCustom";
import { getDaysFromString } from "../config";
import { countryData } from "../pages/countries/countryData";
import loadCountries from "../api/loadCountries";

const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigateCustom();
  const [countries, setCountries] = useState([])


  const [openSelect1, setOpenSelect1] = useState(false);
  const [openSelect2, setOpenSelect2] = useState(false);
  const [openSelect3, setOpenSelect3] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");

  const [vehicles, setVehicles] = useState([]);
  const [products, setProducts] = useState([]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  useEffect(() => {
    dispatch({ type: "CLEAR_VIG_DETAILS" });
    // eslint-disable-next-line
  }, []);

  //   load vehicles
  const loadVehicles = async (countryName) => {
    setVehicles([]);
    try {
      setOpenSelect2(true);
      const res = await axios.get(
        `/products?country=${encodeURI(countryName)}`
      );
      setVehicles(res.data);
      dispatch({ type: "ADD_VEHICLES", payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };

  // load products
  const loadProducts = async (productId) => {
    setProducts([]);
    if (!productId) return;
    try {
      setOpenSelect3(true);
      const res = await axios.get(`/product/${productId}`);
      const data = res.data?.combinations || [];
      setProducts(data);
      dispatch({ type: "ADD_PRODUCTS", payload: data });
    } catch (err) {
      console.log("error loading product", err);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      loadVehicles(selectedCountry);
    }
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch({ type: "SELECT_" + selectedCategory.engName.toUpperCase() });
      dispatch({
        type: "SET_VIG_DETAILS",
        payload: {
          productId: selectedCategory?.productId,
        },
      });

      loadProducts(selectedCategory?.productId);
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedDuration) {
      const currKeys = Object.keys(selectedDuration.price);
      dispatch({
        type: "SET_CURRENCY",
        payload: {
          currency: currKeys[0],
          currencies: currKeys,
        },
      });
      dispatch({
        type: "SET_PRICE",
        payload: {
          ecoFee: selectedDuration.ecoFee,
          price: selectedDuration.price,
        },
      });
      dispatch({
        type: "SET_VIG_DETAILS",
        payload: {
          validDate: getDaysFromString(selectedDuration.engName),
          combinationId: selectedDuration.combinationId,
        },
      });
    }
    // eslint-disable-next-line
  }, [selectedDuration]);

  useEffect(() => {
    const getCountries = async () => {
      setCountries(await loadCountries());
    };

    getCountries();
  }, [])

  const handleSubmit = () => {
    if (!selectedCountry) {
      setOpenSelect1(true);
      return;
    }
    if (!selectedCategory) {
      setOpenSelect2(true);
      return;
    }
    if (!selectedDuration) {
      setOpenSelect3(true);
      return;
    }
    dispatch({ type: "SET_USED_SEARCH_BAR" });
    navigate(
      `/country/${selectedCountry.toLowerCase().replace(" ", "-")}/checkout`
    );
  };

  return (
    <div className="searchBar">

      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="vehicle-country">Vehicle Country</InputLabel>
        <Select
          fullWidth
          variant="outlined"
          label="Vehicle Country"
          className="searchSelect"
          id="vehicle-country"
          open={openSelect1}
          onClose={() => setOpenSelect1(false)}
          onOpen={() => setOpenSelect1(true)}
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          {/* For showing the countries */}
          {countries.map(({ name, category_id }) => {
            let lng = localStorage.getItem("lng");
            if (!Object.hasOwn(name, lng)) lng = "en";
            return (
              <MenuItem
                key={category_id}
                value={name["en"]}
                onClick={() => setSelectedCountry(name["en"])}
              >
                <div className="optionsDiv ">
                  <img
                    loading="lazy"
                    src={
                      countryData[name["en"].toLowerCase().replace(" ", "-")]
                        .flag
                    }
                    className="flagImg"
                    alt="vehicleImage"
                  />
                  {name[lng]}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="vehicle-category">Vehicle Category</InputLabel>
        <Select
          fullWidth
          variant="outlined"
          label="Vehicle Category"
          className="searchSelect"
          id="vehicle-category"
          open={openSelect2}
          onClose={() => setOpenSelect2(false)}
          onOpen={() => setOpenSelect2(true)}
        >
          {/* For showing the vehciles (second select option) */}
          {vehicles.length === 0 ? (
            <div className="placeholderOption">
              {selectedCountry ? (
                <>
                  <CircularProgress thickness={5} size={20} />
                  <p>Fetching categories...</p>
                </>
              ) : (
                "Please select country"
              )}
            </div>
          ) : (
            vehicles.map(({ name, product_id, image_id }) => {
              let lng = localStorage.getItem("lng");
              if (!Object.hasOwn(name, lng)) lng = "en";
              return (
                <MenuItem
                  key={product_id}
                  value={name["en"]}
                  onClick={() =>
                    setSelectedCategory({
                      productId: product_id,
                      name: name[lng],
                      engName: name["en"],
                    })
                  }
                >
                  <div className="optionsDiv">
                    <img
                      src={`${axios.defaults.baseURL}/image/${image_id}`}
                      alt="vehicleImage"
                    />
                    {name[lng]}
                  </div>
                </MenuItem>
              );
            })
          )}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="duration">Duration</InputLabel>
        <Select
          fullWidth
          variant="outlined"
          label="Duration"
          className="searchSelect"
          id="duration"
          open={openSelect3}
          onClose={() => setOpenSelect3(false)}
          onOpen={() => setOpenSelect3(true)}
          value={selectedDuration?.name}
        >
          {/* For choosing the duration (third select option) */}
          {products.length === 0 ? (
            <div className="placeholderOption">
              {selectedCategory ? (
                <>
                  <CircularProgress thickness={5} size={20} />
                  <p>Fetching durations...</p>
                </>
              ) : (
                "Please select category first"
              )}
            </div>
          ) : (
            products.map(
              ({ name, combination_id, image_id, price, eko_fees }) => {
                let lng = localStorage.getItem("lng");
                if (!Object.hasOwn(name, lng)) lng = "en";
                return (
                  <MenuItem
                    key={combination_id}
                    value={name["en"]}
                    onClick={() =>
                      setSelectedDuration({
                        combinationId: combination_id,
                        name: name[lng],
                        engName: name["en"],
                        price,
                        ecoFee: eko_fees,
                      })
                    }
                  >
                    <div className="optionsDiv">
                      <img
                        src={`${axios.defaults.baseURL}/image/${image_id}`}
                        alt="vehicleImage"
                      />
                      {name[lng]}
                    </div>
                  </MenuItem>
                );
              }
            )
          )}
        </Select>
      </FormControl>

      <Button

        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className="searchButton"
        style={{ boxShadow: "none" }}
      >
        Find
      </Button>

    </div>
  );
};

export default SearchBar;
