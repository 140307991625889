import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n/i18n";
import { Provider } from "react-redux";
import allReducers from "./reducers";
import registerServiceWorker from "./utils/serviceWorkerUtils";
import { legacy_createStore } from "redux";
import FallbackPageLoader from "./components/FallbackPageLoader";
import "./styles/index.scss"




const store = legacy_createStore(
  allReducers,
);



registerServiceWorker()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<FallbackPageLoader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>

);


