const initialState = {
  selectedOption: "cars",
  vignetteprices: ["500", "600", "700"],
};

export const vehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_TRUCK":
      return {
        ...state,
        selectedOption: "truck",
        vignetteprices: ["1500", "1600", "1700"],
      };
    case "SELECT_TROLLEY":
      return {
        ...state,
        selectedOption: "trolley",
        vignetteprices: ["1500", "1600", "1700"],
      };
    case "SELECT_CARS":
      return {
        ...state,
        selectedOption: "cars",
        vignetteprices: ["500", "600", "700"],
      };
    case "SELECT_MOTORBIKES":
      return {
        ...state,
        selectedOption: "motorbikes",
        vignetteprices: ["500", "600", "700"], // TODO: needs changes here too
      };
    case "SELECT_NULL":
      return {
        ...state,
        selectedOption: "cars",
        vignetteprices: ["500", "600", "700"],
      };
    default:
      return state;
  }
};
