const initialState = {
  vehicles: [],
  products: [],
  selectedVig: {},
  usedSearchBar: false,
};

export const checkoutInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_VEHICLES":
      return {
        ...state,
        vehicles: action.payload,
      };
    case "ADD_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };
    case "SET_VIG_DETAILS":
      return {
        ...state,
        selectedVig: { ...state.selectedVig, ...action.payload },
      };
    case "SET_USED_SEARCH_BAR":
      return {
        ...state,
        usedSearchBar: true,
      };

    case "UNSET_USED_SEARCH_BAR":
      return {
        ...state,
        usedSearchBar: false,
      };

    case "CLEAR_VIG_DETAILS":
      return initialState;
    default:
      return state;
  }
};
