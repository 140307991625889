import banner from "../../../assets/images/countries/slovaka3-mid.webp";
import flag from "../../../assets/images/flags/polish.webp";

const polandData = {
  banner,
  flag,
  name: "Poland",
  initials: "PL",
  navigateOnSelectLink: "/slovakia/checkout",
  mission: "otherPage.mission",
  travelTo: "Poland page heading",
  chooseYourVehicle: "otherPage.chooseYourVehicleForSlovakia",
  eVigInCountry: "otherPage.eVigInSlovak",
  eVigInCountryDesc: "otherPage.buyingAnEVigInSlovak",
  typesOfVigDescCountry: "otherPage.typesOfVDescSlovak",
  aboutCountry: "otherPage.aboutSlovak",
  aboutCountryDesc: "otherPage.aboutDescSlovak",
};

export default polandData;
