import austriaData from "./countriesData/austriaData";
import slovakiaData from "./countriesData/slovakiaData";
import czechRepublicData from "./countriesData/czechRepublicData";

import bulgariaData from "./countriesData/bulgariaData";
import croatiaData from "./countriesData/croatiaData";
import franceData from "./countriesData/franceData";
import hungaryData from "./countriesData/hungaryData";
import italyData from "./countriesData/italyData";
import moldovaData from "./countriesData/moldovaData";
import romaniaData from "./countriesData/romaniaData";
import sloveniaData from "./countriesData/sloveniaData";
import polandData from "./countriesData/polandData";

export const countryData = {
  austria: austriaData,
  slovakia: slovakiaData,
  "czech-republic": czechRepublicData,
  bulgaria: bulgariaData,
  croatia: croatiaData,
  france: franceData,
  hungary: hungaryData,
  italy: italyData,
  moldova: moldovaData,
  romania: romaniaData,
  slovenia: sloveniaData,
  poland: polandData,
};
