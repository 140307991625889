import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";




i18n.use(initReactI18next).use(LanguageDetector).use(Backend).init({
  fallbackLng : "en",
  lng : localStorage.getItem("lng")||"en", //inorder to get the same language even when user refresh the page. 
  debug : false,
  detection:{
       order :['htmlTag','cookie','localStorage','path','subdomain'],
       cache:['cookie'],
  },
  backend:{
     loadPath:"/locales/{{lng}}/homepage.json"
  }
})


export default i18n;