import { Container } from "@mui/material"
import { useTranslation } from "react-i18next";
import verfied from "../../assets/images/verified.webp";
import fast from "../../assets/images/fast.webp";



const HeaderCard = ({
    scrollToContriesGrid,
}) => {
    const { t } = useTranslation();


    return (
        <Container className="topheader-container">
            <div className="header-container">
                <p>{t("homepage.mission")}</p>
                <h2>
                    {t("homepage.header")}
                </h2>
            </div>
            <div className="feature-container">
                <div className="feature-sub-container1">
                    <img loading="lazy" alt="fast" src={fast} />
                    <h3>{t("homepage.verified")}</h3>
                </div>
                <div className="feature-sub-container1">
                    <img loading="lazy" alt="" src={verfied} />
                    <h3>{t("homepage.secure")}</h3>
                </div>
            </div>
        </Container>
    )
}
export default HeaderCard