export const getDaysFromString = (str) => {
  // add more cases for other durations
  switch (str) {
    case "Annual":
      return 365;
    case "1 Month":
      return 30;



    case "10 Days":
      return 10;
    default:
      return 1;
  }
};
