import banner from "../../../assets/images/countries/slovaka3-mid.webp";
import bannerSmall from "../../../assets/images/countries/slovaka3-small.webp";
import flag from "../../../assets/images/flags/slovak.webp";

const slovakiaData = {
  banner,
  bannerSmall,
  flag,
  name: "Slovakia",
  initials: "SK",
  navigateOnSelectLink: "/slovakia/checkout",
  mission: "otherPage.mission",
  travelTo: "otherPage.travelToSlovakia",
  chooseYourVehicle: "otherPage.chooseYourVehicleForSlovakia",
  eVigInCountry: "otherPage.eVigInSlovak",
  eVigInCountryDesc: "otherPage.buyingAnEVigInSlovak",
  typesOfVigDescCountry: "otherPage.typesOfVDescSlovak",
  aboutCountry: "otherPage.aboutSlovak",
  aboutCountryDesc: "otherPage.aboutDescSlovak",
};

export default slovakiaData;
