import "../styles/fallbackCompLoader.scss"
import CarLoader from "./CarLoader"





// LOADER IS VISIBLE WHEN  A COMPONENT TAKES TIME TO LOAD ,
//  SO TH ELOADER TAKES WIDTH & HEIGHT OF ITS PARENT COMPONENT

const FallbackCompLoader = () => {
    return (
        <div className="fallbackCompLoader">
            <CarLoader />
        </div>
    )
}
export default FallbackCompLoader