import banner from "../../../assets/images/countries/slovaka3-mid.webp";
import flag from "../../../assets/images/flags/romanian.webp";

const romaniaData = {
  banner,
  flag,
  name: "Romania",
  initials: "ROU", // not sure about the initials
  navigateOnSelectLink: "/romania/checkout",
  mission: "otherPage.mission",
  travelTo: "Romania page heading",
  chooseYourVehicle: "otherPage.chooseYourVehicleForSlovakia",
  eVigInCountry: "otherPage.eVigInSlovak",
  eVigInCountryDesc: "otherPage.buyingAnEVigInSlovak",
  typesOfVigDescCountry: "otherPage.typesOfVDescSlovak",
  aboutCountry: "otherPage.aboutSlovak",
  aboutCountryDesc: "otherPage.aboutDescSlovak",
};

export default romaniaData;
