import React, { useEffect } from 'react'
import i18n from "../i18n/i18n";
import { useTranslation } from "react-i18next";

function DynamicTitle() {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);
    return (
        <></>
    )
}

export default DynamicTitle