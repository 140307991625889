import "../styles/fallbackLoader.scss"
import CarLoader from "./CarLoader"

// LOADER IS VISIBLE WHEN  A PAGE TAKES TIME TO LOAD ,
//  SO TH ELOADER TAKES WIDTH & HEIGHT OF SCREEN
const FallbackPageLoader = () => {
    return (
        <div className="fallbackPageLoader">
            <CarLoader />
        </div>
    )
}
export default FallbackPageLoader