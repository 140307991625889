
const HeaderWithStyledLastWord = ({ text, numLastWords = 1 }) => {
    // Split the text into words
    const words = text.split(' ');

    // Ensure numLastWords is not greater than the number of words
    const validNumLastWords = Math.min(numLastWords, words.length);

    // Get all words except the last `numLastWords`
    const allButLast = words.slice(0, -validNumLastWords).join(' ');

    // Get the last `numLastWords` words
    const lastWords = words.slice(-validNumLastWords).join(' ');

    return (
        <h1>
            {allButLast} <span>{lastWords}</span>
        </h1>
    );
};


export default HeaderWithStyledLastWord