import React from "react";
import call from "../assets/images/call.webp";
import message from "../assets/images/message.webp";
import brandLogo from "../assets/images/newLogoEu.webp";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "../styles/footer.scss";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation()
  const customUrlString = (link) => {
    return `/${localStorage.getItem("i18nextLng")}/${link}`;
  };
  return (
    <div className="footercontainer">

      {/* UPPER FOOTER  */}
      <div className="footer">
        <div className="footer-container">
          <div container  >
            <Grid item xs={12} md={6}>
              <Link to={customUrlString("")} className="footer-brand">
                <img loading="lazy"
                  className="footer-brand-logo"
                  src={brandLogo}
                  alt="carlogo"
                  fetchPriority="low"
                />
                <h2>vignetto.eu</h2>
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="footer-links">
                <div><Link to={customUrlString("faq")} className={`${pathname.includes('faq') ? 'activeNavLink' : ""}`}>FAQs</Link></div>
                <div><Link to={customUrlString("contact")} className={`${pathname.includes('contact') ? 'activeNavLink' : ""}`}>Contact Us</Link></div>
              </div>
            </Grid>
          </div>
        </div>
      </div>

      {/* LOWER FOOTER  */}
      <div className="contact-footer">
        <div className="contact-footer-container">
          <Grid container className="grid-container">
            <Grid item xs={12} md={8} lg={6} className="contact-details">
              <p>{t("homepage.quickHelp")}</p>

              <div>
                <h4>
                  <img loading="lazy" src={call} alt="call" fetchPriority="low" />
                  <a href="tel:+34-8767859664">+34-8767859664</a>
                </h4>
                <h5>
                  <img loading="lazy" src={message} alt="message" className="messageicon" fetchPriority="low" />
                  <a href="mailto:help@vignette.com">help@vignette.com</a>
                </h5>
              </div>
            </Grid>
            <Grid item xs={12} md={8} lg={6} className="copyright">
              <p>&#169; 2023 vignetto.eu</p>

            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Footer;
