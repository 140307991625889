import React from "react";
import Lottie from "react-lottie";

const AnimatedLottie = ({ animationData, width = 400, loop = true }) => {
  const defaultOptions = {
    loop,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={width} width={width} />
    </div>
  );
};

export default AnimatedLottie;
