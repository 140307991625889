import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const languages = ["cs", "de", "en", "hu", "it", "pl", "sk", "sl", "uk"];

const useLanguage = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      return;
    } else if (path.startsWith("/order/success")) {
      window.location.pathname = `/${localStorage.getItem(
        "i18nextLng"
      )}${path}`;
    } else {

      let vals = path.split("/");
      if (!languages.includes(vals[1])) {
        vals[1] = "en";
        const pathStr = vals.join("/");
        window.location.pathname = pathStr;
      }
      localStorage.setItem("lng", vals[1]);
      i18n.changeLanguage(vals[1]);
    }
  }, [i18n]);
};

export default useLanguage;
