import banner from "../../../assets/images/countries/slovaka3-mid.webp";
import flag from "../../../assets/images/flags/croatian.webp";

const croatiaData = {
  banner,
  flag,
  name: "Croatia",
  initials: "HR",
  navigateOnSelectLink: "/croatia/checkout",
  mission: "otherPage.mission",
  travelTo: "Croatia page heading",
  chooseYourVehicle: "otherPage.chooseYourVehicleForSlovakia",
  eVigInCountry: "otherPage.eVigInSlovak",
  eVigInCountryDesc: "otherPage.buyingAnEVigInSlovak",
  typesOfVigDescCountry: "otherPage.typesOfVDescSlovak",
  aboutCountry: "otherPage.aboutSlovak",
  aboutCountryDesc: "otherPage.aboutDescSlovak",
};

export default croatiaData;
